import { Account, PingQuery } from '../graphql/generated';
import fmtAddress from './fmtAddress';

interface IAccount2 extends Pick<Account, 'walletAddress' | 'username' | 'email'> {

}

type IAccount = PingQuery['ping'];

const fmtUser = (account: IAccount | IAccount2 | null | undefined): string => {
    if (!account) {
        return 'User not found';
    }

    if (account.username) {
        return account.username;
    }

    if (account.email) {
        return account.email;
    }

    if (account.walletAddress) {
        return fmtAddress(account.walletAddress);
    }

    return 'Deleted user';
}

export default fmtUser;