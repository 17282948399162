const Button = {
    baseStyle: {
        borderRadius: '5px',
        px: '14px',
        py: '8px',
        track: {
            _focus: {
                boxShadow: 'none'
            }
        }
    },
    colorScheme: {
        pagination: {
            background: 'black',
            // color: 'white',
            _hover: {
                background: 'black',
                color: 'white'
            },
            _active: {
                background: 'black',
                color: 'white'
            },
            _disabled: {
                color: 'gray.400'
            }
        },
    },
    // Some sizes are commented out because there might be an undesirable difference in button styles.
    sizes: {
        '2xl': {
            fontSize: 'lg',
            fontWeight: 600,
            px: '28px',
            py: '16px',
        },
        'xl': {
            fontSize: 'md',
            fontWeight: 600,
            px: '20px',
            py: '12px',
        },
        'lg': {
            fontSize: 'md',
            fontWeight: 600,
            px: '18px',
            py: '10px',
        },
        'md': {
            fontSize: 'sm',
            fontWeight: 600,
            px: '16px',
            py: '10px',
        },
        'sm': {
            fontSize: 'sm',
            fontWeight: 600,
            px: '14px',
            py: '8px',
        },
        'xs': {
            fontSize: 'sm',
            fontWeight: 600,
            px: '14px',
            py: '8px',
        },
        'xxs': {
            fontSize: 'xs',
            fontWeight: 600,
            px: '10px',
            py: '4px',
        },
    },
    variants: {
        black: {
            bg: 'black',
            color: 'white',
            _hover: {
                bg: 'gray.700',
                _disabled: {
                    bg: 'gray.800'
                }
            },
            _active: {
                bg: 'gray.800',
            },
        },
    }
};

export default Button;
