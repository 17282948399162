import React, { useCallback, useState } from 'react';
import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text, useToast
} from '@chakra-ui/react';
import { useMissingTxLazyQuery, useMissingTxQuery } from '../../graphql/generated';

interface LostTransactionModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const LostTransactionModal: React.FC<LostTransactionModalProps> = ({ isOpen, onClose }) => {
    const toast = useToast();
    const [txId, setTxId] = useState<string>('');

    const [fetchTx, { loading }] = useMissingTxLazyQuery({
        onError(err) {
            toast({
                description: err.message,
                position: 'top',
                isClosable: true,
                duration: 9000,
                status: 'error'
            });
            onCloseModal();
        },
        onCompleted(data) {
            if (!!data?.searchMissingPayment) {
                toast({
                    title: 'Payment updated',
                    description: 'Please refresh the page',
                    position: 'top',
                    isClosable: true,
                    duration: 9000,
                    status: 'success'
                });
            } else {
                toast({
                    title: 'Transaction not found',
                    description: 'Please double check the transaction ID',
                    position: 'top',
                    isClosable: true,
                    duration: 9000,
                    status: 'error'
                });
            }
            onCloseModal();

        }
    });

    const onCloseModal = useCallback(() => {
        onClose();
        setTxId('');
    }, [txId]);

    const onSearch = useCallback(async () => {
        const resp = await fetchTx({ variables: { txHash: txId } });
    }, [txId]);

    return (
        <Modal isOpen={isOpen} onClose={onCloseModal} size='lg'>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    Lost transaction?
                </ModalHeader>
                <ModalBody py={2}>
                    <Stack color='gray.600' gap={0}>
                        <Text size='sm'>
                            If you submitted a payment and it hasn't been confirmed yet, it might be lost.
                        </Text>
                        <Text size='sm'>
                            Enter the transaction ID below and we will fetch your payment.
                        </Text>
                    </Stack>
                    <Stack mt={4} gap={0}>
                        <Input placeholder='' value={txId} onChange={e => setTxId(e.target.value)}/>
                        <Button variant='black' isLoading={loading} onClick={onSearch}>
                            Fetch transaction
                        </Button>
                    </Stack>
                    <Text
                        as='a'
                        color='blue.400'
                        mt={2}
                        href='https://basescan.org/address/0x4A5cb7372DF9243FD680d00c10E3F52B8849bEAA'
                        size='sm'
                        target='_blank'>
                        See Payment Contract on BaseScan
                    </Text>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default LostTransactionModal;
