import { walletProvider, WalletProviderButton } from './WalletProviderButton';
import { Flex, Link } from '@chakra-ui/react';
import React, { useContext, useState } from 'react';
import { useAccount, useConnect, useSignMessage } from 'wagmi';
import { useTokenQuery, useWalletLoginMutation } from '../../graphql/generated';
import { connector } from '../Providers/Web3Provider';
import { AuthContext } from '../Providers/AuthProvider';

export const getMessagedSigned = (token: string): string => {
    return `Sign this one time Token to login to the Longevity Review.

${token}
 
!Make sure the host is https://longevity.review!`;
}

const ConnectWallet: React.FC<{ onClose?: () => void }> = ({ onClose }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { setAuthValues } = useContext(AuthContext);
    const { isConnected, address } = useAccount();
    const { connectAsync, connectors} = useConnect({ connector });

    const { signMessageAsync } = useSignMessage();
    const { data: token } = useTokenQuery();
    const [walletLogin] = useWalletLoginMutation();

    const onConnect = async (connectorId: string) => {
        setLoading(true);
        const connector = connectors.find((c: any) => c.id === connectorId);
        const tokenToSign = token?.signatureToken.token;

        if (!connector || !tokenToSign) {
            return;
        }

        try {
            let account = address ?? '';
            if (!isConnected) {
                const { account: walletAccount } = await connectAsync();
                account = walletAccount;
            }
            const signed = await signMessageAsync({ message: getMessagedSigned(tokenToSign) });
            const loggedIn = await walletLogin({
                variables: {
                    input: {
                        token: tokenToSign,
                        walletAddress: account,
                        signature: signed ?? ''
                    }
                }
            });
            if (loggedIn.data) {
                setAuthValues(loggedIn.data.walletLogin, true);
            }
            if (onClose) {
                onClose();
            }
        } catch (e: any) {
            console.log(e, typeof e, e?.message, Object.keys(e));
        }
        setLoading(false);
    }

    return (
        <Flex maxWidth={280} align='middle' textAlign='center' direction='column' gap='16px'>
            {connectors.map((connector: any) => (
                <WalletProviderButton
                    key={connector.id}
                    loadingText={connector.name}
                    isLoading={loading}
                    onClick={() => onConnect(connector.id)}
                    provider={walletProvider[connector.name]}
                    disabled={!connector.ready}>
                    { connector.name }
                    { !connector.ready && ' (unsupported)' }
                </WalletProviderButton>
            ))}

            <Link
                isExternal
                href='https://ethereum.org/en/wallets/find-wallet/'
                fontWeight='bold'
                mt={10}
                target='_blank'>
                Learn more about wallets here
            </Link>
        </Flex>
    )
}

export default ConnectWallet;
