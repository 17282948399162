import 'react-quill/dist/quill.snow.css';
import ReactGA from 'react-ga4';
import React from 'react';
import ReactDOM from 'react-dom/client';
import '@fontsource/montserrat/400-italic.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500-italic.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600-italic.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700-italic.css';
import '@fontsource/montserrat/700.css';

import reportWebVitals from './reportWebVitals';
import chakraTheme from './styles';
import { ChakraProvider } from '@chakra-ui/react';
import AppRoutes from './AppRoutes';
import { Web3Provider } from './components/Providers/Web3Provider';
import { apolloClient } from './config';
import { ApolloProvider } from '@apollo/client';
import AuthProvider from './components/Providers/AuthProvider';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

ReactGA.initialize('G-4VCRYQMGXQ')

root.render(
    <React.StrictMode>
        <ApolloProvider client={apolloClient}>
            <Web3Provider>
                <ChakraProvider theme={chakraTheme} resetCSS={true}>
                    <AuthProvider>
                        <AppRoutes />
                    </AuthProvider>
                </ChakraProvider>
            </Web3Provider>
        </ApolloProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
