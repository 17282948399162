import { extendTheme } from '@chakra-ui/react';

import colors from './colors';
import fonts from './fonts';
import shadows from './shadows';
import styles from './styles';
import zIndices from './zIndices';

import Button from './components/Button';
import Heading from './components/Heading';
import Text from './components/Text';
import Link from './components/Link';
import ListItem from './components/ListItem';
import Input from './components/Input';

export default extendTheme({
    colors,
    components: {
        Button,
        Heading,
        Input,
        Link,
        ListItem,
        Text,
    },
    fonts,
    shadows,
    styles,
    zIndices,
});
