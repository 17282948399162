import React from 'react';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';
import { Flex, FormControl, FormErrorMessage, FormLabel, Input, Textarea } from '@chakra-ui/react';
import { RegSchema } from './index';

interface IRegisterForm {
    registerForm: UseFormReturn<RegSchema>;
    isRegister: boolean;
}

const RegisterForm: React.FC<IRegisterForm> = ({ registerForm, isRegister }) => {
    const { control, formState: { errors } } = registerForm;
    return (
        <FormProvider {...registerForm}>
            <FormControl isRequired isInvalid={!!errors?.email}>
                <FormLabel>Email</FormLabel>
                <Controller
                    control={control}
                    render={({ field: { onChange, value }}: any) => (
                        <>
                            <Input
                                h={10}
                                bg='gray.50'
                                onChange={onChange}
                                placeholder='email'
                                size='sm'
                                type='text'
                                value={value} />
                            <FormErrorMessage>
                                { errors?.email?.message }
                            </FormErrorMessage>
                        </>
                    )}
                    name='email'
                    defaultValue='' />
            </FormControl>
            <FormControl isRequired isInvalid={!!errors?.password}>
                <FormLabel>Password</FormLabel>
                <Controller
                    control={control}
                    render={({ field: { onChange, value }}: any) => (
                        <>
                            <Input
                                h={10}
                                bg='gray.50'
                                onChange={onChange}
                                placeholder='password'
                                size='sm'
                                type='password'
                                value={value} />
                            <FormErrorMessage>
                                { errors?.password?.message }
                            </FormErrorMessage>
                        </>
                    )}
                    name='password'
                    defaultValue='' />
            </FormControl>
            {isRegister &&
                <>
                    <FormControl isInvalid={!!errors?.username}>
                        <FormLabel>Username</FormLabel>
                        <Controller
                            control={control}
                            render={({ field: { onChange, value }}: any) => (
                                <>
                                    <Input
                                        h={50}
                                        bg='gray.50'
                                        onChange={onChange}
                                        placeholder='Username'
                                        size='sm'
                                        value={value} />
                                    <FormErrorMessage>
                                        { errors?.username?.message }
                                    </FormErrorMessage>
                                </>
                            )}
                            name='username'
                            defaultValue='' />
                    </FormControl>
                    <Flex direction='row'>
                        <FormControl isInvalid={!!errors?.firstName}>
                            <FormLabel>First Name</FormLabel>
                            <Controller
                                control={control}
                                render={({ field: { onChange, value }}: any) => (
                                    <>
                                        <Input
                                            autoComplete='off'
                                            bg='gray.50'
                                            h={50}
                                            onChange={onChange}
                                            placeholder='First Name'
                                            size='sm'
                                            value={value} />
                                        <FormErrorMessage>
                                            { errors?.firstName?.message }
                                        </FormErrorMessage>
                                    </>
                                )}
                                name='firstName'
                                defaultValue='' />
                        </FormControl>
                        <FormControl isInvalid={!!errors?.lastName}>
                            <FormLabel>Last name</FormLabel>
                            <Controller
                                control={control}
                                render={({ field: { onChange, value }}: any) => (
                                    <>
                                        <Input
                                            autoComplete='off'
                                            bg='gray.50'
                                            h={50}
                                            onChange={onChange}
                                            placeholder='Last Name'
                                            size='sm'
                                            value={value} />
                                        <FormErrorMessage>
                                            { errors?.lastName?.message }
                                        </FormErrorMessage>
                                    </>
                                )}
                                name='lastName'
                                defaultValue='' />
                        </FormControl>
                    </Flex>
                    <FormControl isInvalid={!!errors?.bio}>
                        <FormLabel>Bio</FormLabel>
                        <Controller
                            control={control}
                            render={({ field: { onChange, value }}: any) => (
                                <>
                                    <Textarea
                                        bg='gray.50'
                                        onChange={onChange}
                                        placeholder='Bio...'
                                        size='sm'
                                        value={value} />
                                    <FormErrorMessage>
                                        { errors?.bio?.message }
                                    </FormErrorMessage>
                                </>
                            )}
                            name='bio'
                            defaultValue='' />
                    </FormControl>
                </>
            }
        </FormProvider>
    )
}

export default RegisterForm;
