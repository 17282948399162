import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ConnectorData, useAccount } from 'wagmi';
import React, { Suspense, useContext, useEffect } from 'react';
import { useLogoutMutation } from './graphql/generated';
import { AuthContext } from './components/Providers/AuthProvider';
import LoadingScreen from './views/LoadingScreen';
import UploadPaper from './views/UploadPaper';
import Admin from './views/Admin';

const Home = React.lazy(() => import('./views/Home'));
const FAQ = React.lazy(() => import('./views/Faq'));
const Paper = React.lazy(() => import('./views/Paper'));
const Toc = React.lazy(() => import('./views/Toc'));
const Reset = React.lazy(() => import('./views/Reset'));
const Profile = React.lazy(() => import('./views/Profile'));
const NotFound = React.lazy(() => import('./views/NotFound'));

const AppRoutes = () => {
    const { connector: activeConnector } = useAccount();
    const { setAuthValues } = useContext(AuthContext);

    const [logout] = useLogoutMutation();

    useEffect(() => {
        if (!activeConnector) {
            return ;
        }

        const handleConnectorUpdate = async ({ account, chain }: ConnectorData) => {
            if (account) {
                console.log('new account', account);
                await logout();
                setAuthValues(null, false);
            } else if (chain) {
                console.log('new chain', chain)
            }
        }

        if (activeConnector) {
            activeConnector.on('change', handleConnectorUpdate);
        }

        return () => {
            activeConnector.off('change', handleConnectorUpdate);
        }
    }, [activeConnector]);

    return (
        <BrowserRouter>
            <Suspense fallback={<LoadingScreen />}>
                <Routes>
                    <Route path='' element={<Home />} />
                    <Route path='/preprint/:paperId' element={<Paper />} />
                    <Route path='/profile' element={<Profile />} />
                    <Route path='/upload' element={<UploadPaper />} />
                    <Route path='/faq' element={<FAQ />} />
                    <Route path='/reset' element={<Reset />} />
                    <Route path='/terms' element={<Toc />} />
                    <Route path='/xyz/reports' element={<Admin />} />
                    <Route path={'*'} element={<NotFound />}/>
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default AppRoutes;
