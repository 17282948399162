import { Box, Button, Flex, Image, Link, Spacer, Text, useDisclosure } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import RegisterModal from '../Modals/RegisterModal';
import { AuthContext } from '../Providers/AuthProvider';
import fmtUser from '../../utils/fmtUser';
import { useResendConfirmationEmailMutation } from '../../graphql/generated';
import LogoType from '../../assets/logos/logotype.svg';
import LostTransactionModal from '../Modals/LostTransactionModal';

const Header = () => {
    const navigate = useNavigate();
    const { isLoggedIn, account } = useContext(AuthContext);
    const [isResent, setIsResent] = React.useState<boolean>(false)
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isLostOpen, onOpen: onOpenLost, onClose: onCloseLost } = useDisclosure();

    const [resendConfirmEmail, { loading }] = useResendConfirmationEmailMutation();

    const onResendEmail = async () => {
        setIsResent(true);
        await resendConfirmEmail();
    }

    return (
        <>
            { account && !account.confirmed &&
                <Box position='fixed' zIndex={2}
                     top={0} right={0} left={0} color='white' bg='red.800' textAlign='center' py={2}>
                    Email not confirmed! Please check your Email including spam.
                    {!isResent &&
                        <Link onClick={onResendEmail}>
                            <Button size='xxs' variant='black' ml={4} isLoading={loading}>resend</Button>
                        </Link>
                    }
                </Box>
            }
            <Flex w='100%' h={50} p={4} mb={10} mt={account && !account.confirmed ? 30 : 0}>
                <NavLink to='/'>
                    <Image src={LogoType} w={250} />
                </NavLink>
                <Spacer />
                <Flex dir='row' gap={3}>
                    <Button color='blue.400'
                            isDisabled={!isLoggedIn}
                            size='sm'
                            variant='ghost'
                            onClick={onOpenLost}
                            zIndex={1}>
                        Lost payment?
                    </Button>
                    <Button color='black'
                            isDisabled={!isLoggedIn}
                            size='sm'
                            variant='ghost'
                            onClick={() => navigate('/upload')}
                            zIndex={1}>
                        Upload
                    </Button>
                    <Text>
                        { isLoggedIn &&
                            <Button size='sm' variant='black' onClick={() => navigate('/profile')} zIndex={1}>
                                {fmtUser(account)}
                            </Button>
                        }
                        {!isLoggedIn && <Button size='sm' variant='black' onClick={() => onOpen()}>Login</Button> }
                    </Text>
                </Flex>
                <RegisterModal isOpen={isOpen} onClose={onClose} />
                <LostTransactionModal isOpen={isLostOpen} onClose={onCloseLost} />
            </Flex>
        </>
    )
}

export default Header;
