import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, FormControl, FormErrorMessage, FormLabel, Input, useToast } from '@chakra-ui/react';
import { useSendResetPasswordMutation } from '../../../graphql/generated';

const ForgotSchema = z.object({
    email: z.string().email(),
});
type Schema = z.infer<typeof ForgotSchema>;

const ForgotForm: React.FC<{ onClose: any }> = ({ onClose }) => {
    const toast = useToast();
    const [resetPassword, { loading }] = useSendResetPasswordMutation();

    const forgotForm = useForm<Schema>({
        mode: 'onChange',
        resolver: zodResolver(ForgotSchema),
    });
    const { control, formState: { errors } } = forgotForm;

    const onResetPassword = async () => {
        await resetPassword({
            variables: {
                email: forgotForm.getValues('email')
            }
        });
        toast({
            description: 'If an account exists you will receive an email with instructions to reset your password.',
            status: 'success',
            duration: 5000,
            position: 'top',
            isClosable: true,
        });
        onClose();
    }

    return (
        <>
            <FormProvider {...forgotForm}>
                <FormControl isRequired isInvalid={!!errors?.email}>
                    <FormLabel>Email</FormLabel>
                    <Controller
                        control={control}
                        render={({ field: { onChange, value }}: any) => (
                            <>
                                <Input
                                    autoComplete='off'
                                    bg='gray.50'
                                    h={10}
                                    onChange={onChange}
                                    placeholder='Your email'
                                    size='sm'
                                    type='text'
                                    value={value} />
                                <FormErrorMessage>
                                    { errors?.email?.message }
                                </FormErrorMessage>
                            </>
                        )}
                        name='email'
                        defaultValue='' />
                </FormControl>
            </FormProvider>
            <Button
                variant='black'
                mt={3}
                w='100%'
                onClick={onResetPassword}
                isDisabled={loading}
                isLoading={loading}>
                Reset Password
            </Button>
        </>
    )
}

export default ForgotForm;
