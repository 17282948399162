const Text = {
    baseStyle: {
        color: 'textColor',
    },
    sizes: {
        xl: {
            fontSize: '20px',
            lineHeight: '1.5',
        },
        lg: {
            fontSize: '18px',
            lineHeight: '1.56',
        },
        md: {
            fontSize: '16px',
            lineHeight: '1.50',
        },
        sm: {
            fontSize: '14px',
            lineHeight: '1.21',
        },
        xs: {
            fontSize: '10px',
            lineHeight: '1.6',
        },
        xxs: {
            fontSize: '8px'
        }
    },
};

export default Text;