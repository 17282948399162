import { ApolloClient, InMemoryCache } from '@apollo/client';
import { loadStripe } from '@stripe/stripe-js';

export const IS_PROD = process.env.REACT_APP_ENV === 'production';

export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:9000';
export const WEBSOCKET_URL = 'wss://' + (process.env.REACT_API_URL ?? '').replace(/^https?:\/\//, '') || 'ws://localhost:9000';

export const apolloClient = new ApolloClient({
    uri: `${API_URL}/graphql`,
    credentials: 'include',
    cache: new InMemoryCache({
        addTypename: true
    }),
});

export const TOKEN_DECIMALS = 6;
export const PAY_CONTRACT_ADDRESS: any = process.env.REACT_APP_PAY_CONTRACT_ADDRESS as any;
export const PAY_CURRENCY_ADDRESS: any  = process.env.REACT_APP_PAY_CURRENCY_ADDRESS as any;
const STRIPE_PUBLISH_KEY: any  = process.env.REACT_APP_STRIPE_PK as any;

export const stripePromise = loadStripe(STRIPE_PUBLISH_KEY);
