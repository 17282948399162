import { Button, ButtonProps, Center, Icon } from '@chakra-ui/react';
import React from 'react';
import { ReactComponent as WalletConnectIcon } from '../../assets/logos/logo-walletconnet.svg';
// import { ReactComponent as CoinbaseIcon } from '../../assets/icons/logos/logo-coinbase.svg';
import { ReactComponent as MetamaskIcon } from '../../assets/logos/logo-metamask-2.svg';

// Based on the 'connector.name' provided by Web3Context
export enum WalletProvider {
    MetaMask = 'MetaMask',
    WalletConnect = 'WalletConnect',
    // Coinbase = 'Coinbase Wallet'
}

interface WPBtnProps extends ButtonProps {
    provider?: WalletProvider;
}

export const walletProvider: Record<string, WalletProvider> = {
    [WalletProvider.MetaMask]: WalletProvider.MetaMask,
    [WalletProvider.WalletConnect]: WalletProvider.WalletConnect,
    // [WalletProvider.Coinbase]: WalletProvider.Coinbase,
}

const walletProviderIcon: Record<WalletProvider, React.ReactElement> = {
    [WalletProvider.MetaMask]: <Icon as={MetamaskIcon} h='30px' w='30px' />,
    [WalletProvider.WalletConnect]: <Icon as={WalletConnectIcon} h='30px' w='30px' />,
    // [WalletProvider.Coinbase]: <Icon as={CoinbaseIcon} h='30px' w='30px' />
}

export const WalletProviderButton: React.FC<WPBtnProps> = ({
    children,
    provider,
    ...props
}) => {
    return (
        <Button
            fontSize={16}
            iconSpacing='8px'
            padding='16px'
            size='xl'
            variant='black'
            w='100%'
            {...props}>
            <Center gap={3} width='100%' height='100%'>
                {provider ? walletProviderIcon[provider] : undefined}
                {children}
            </Center>
        </Button>
    );
};
