import { Divider, Flex, Link, Spacer, Text } from '@chakra-ui/react';
import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <Flex dir='row' w='100%' mt={10} mb={4} pt={10} borderTopColor='gray.200' borderTopWidth={1}>
            <Flex fontSize='sm' gap={3} color='black' fontWeight='bold'>
                <NavLink to='/terms'>Terms</NavLink>
                <Divider orientation='vertical' />
                <NavLink to='/faq'>About</NavLink>
                <Divider orientation='vertical' />
                <Link href='mailto:info@longevity.review'>Contact Us</Link>
            </Flex>
            <Spacer />
            <Text size='sm'>© {new Date().getFullYear()} The Longevity Decentralized Review</Text>
        </Flex>
    );
}

export default Footer;
