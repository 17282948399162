import React from 'react';
import { Center, keyframes } from '@chakra-ui/react';

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const LoadingScreen: React.FC = () => {
    return (
        <Center
            bg='white'
            // backgroundImage={`url(${BackgroundImage})`}
            // backgroundSize='cover'
            // backgroundRepeat='no-repeat'
            width='100%'
            height='100vh'
            animation={`${fadeIn} 200ms ease-in-out`}
            flexDirection='column' />
    );
};

export default LoadingScreen;
