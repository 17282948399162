import { Box, Container, Flex } from '@chakra-ui/react';
import React from 'react';
import Header from './Header';
import Footer from './Footer';

const ViewContainer: React.FC<{ children: any }> = props => {
    return (
        <Container maxW='1280px'>
            <Flex direction='column' minHeight='100vh'>
                <Box flex='1'>
                    <Header />
                    { props.children }
                </Box>
                <Footer />
            </Flex>
        </Container>
    );
}

export default ViewContainer;
