/* eslint-disable */
import { z } from 'zod';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: Date; output: Date };
  LocalTime: { input: any; output: any };
};

export type Account = {
  __typename?: 'Account';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isAdmin: Scalars['Boolean']['output'];
  isOld?: Maybe<Scalars['Boolean']['output']>;
  isStaff: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  payments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  reviews?: Maybe<Array<PaperReview>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  walletAddress?: Maybe<Scalars['String']['output']>;
};

export type AccountPayload = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  walletAddress?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePaperReview = {
  paperId: Scalars['Int']['input'];
  review: Scalars['String']['input'];
};

export type LoginPayload = {
  email?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  confirmEmail: Scalars['Boolean']['output'];
  createPaymentIntent: PaymentIntentResponse;
  login?: Maybe<Account>;
  logout?: Maybe<Account>;
  registerAccount: Account;
  resendConfirmEmail: Scalars['Boolean']['output'];
  resetPassword: Scalars['Boolean']['output'];
  reviewPaper?: Maybe<Paper>;
  root: Scalars['String']['output'];
  sendResetPassword: Scalars['Boolean']['output'];
  updateAccount: Account;
  uploadPaper: Paper;
  vote?: Maybe<Paper>;
  voteReview?: Maybe<PaperReview>;
  walletLogin?: Maybe<Account>;
};

export type MutationConfirmEmailArgs = {
  token: Scalars['String']['input'];
};

export type MutationCreatePaymentIntentArgs = {
  amount: Scalars['Int']['input'];
  currency: Scalars['String']['input'];
  paperId: Scalars['Int']['input'];
};

export type MutationLoginArgs = {
  input: LoginPayload;
};

export type MutationRegisterAccountArgs = {
  input: AccountPayload;
};

export type MutationResetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationReviewPaperArgs = {
  input: CreatePaperReview;
};

export type MutationSendResetPasswordArgs = {
  email: Scalars['String']['input'];
};

export type MutationUpdateAccountArgs = {
  input: AccountPayload;
};

export type MutationUploadPaperArgs = {
  input: UploadPaperInput;
};

export type MutationVoteArgs = {
  paperId: Scalars['Int']['input'];
};

export type MutationVoteReviewArgs = {
  reviewId: Scalars['Int']['input'];
};

export type MutationWalletLoginArgs = {
  input: WalletPayload;
};

export type Paper = {
  __typename?: 'Paper';
  abstract: Scalars['String']['output'];
  chainId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  paidUntil?: Maybe<Scalars['Date']['output']>;
  reviews?: Maybe<Array<PaperReview>>;
  sourcePlatform: Scalars['String']['output'];
  title: Scalars['String']['output'];
  uploadedBy?: Maybe<Account>;
  uploadedOn: Scalars['Date']['output'];
  upvotedByAccount?: Maybe<Scalars['Boolean']['output']>;
  url: Scalars['String']['output'];
  votes?: Maybe<Scalars['Int']['output']>;
};

export type PaperFilters = {
  onlyPaid?: InputMaybe<Scalars['Boolean']['input']>;
  onlyUser?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};

export type PaperReview = {
  __typename?: 'PaperReview';
  account?: Maybe<Account>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  paper?: Maybe<Paper>;
  paperId?: Maybe<Scalars['Int']['output']>;
  review: Scalars['String']['output'];
  upvotedByAccount?: Maybe<Scalars['Boolean']['output']>;
  votes?: Maybe<Scalars['Int']['output']>;
};

export type PapersResponse = {
  __typename?: 'PapersResponse';
  count: Scalars['Int']['output'];
  papers: Array<Paper>;
};

export type PaymentIntentResponse = {
  __typename?: 'PaymentIntentResponse';
  clientSecret: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  account: Account;
  paper: Paper;
  papers: PapersResponse;
  ping?: Maybe<Account>;
  report: Array<Maybe<ReviewUpvoteReport>>;
  root: Scalars['String']['output'];
  searchMissingPayment: Scalars['Boolean']['output'];
  signatureToken: Token;
  userPapers: Array<Paper>;
  validateResetPasswordToken: Scalars['Boolean']['output'];
};

export type QueryPaperArgs = {
  paperId: Scalars['Int']['input'];
};

export type QueryPapersArgs = {
  filters?: InputMaybe<PaperFilters>;
};

export type QueryReportArgs = {
  filter?: InputMaybe<VoteReportInput>;
};

export type QuerySearchMissingPaymentArgs = {
  tx: Scalars['String']['input'];
};

export type QueryValidateResetPasswordTokenArgs = {
  token: Scalars['String']['input'];
};

export type ReviewUpvoteReport = {
  __typename?: 'ReviewUpvoteReport';
  count: Scalars['Int']['output'];
  review: PaperReview;
};

export type Token = {
  __typename?: 'Token';
  token: Scalars['String']['output'];
};

export type UploadPaperInput = {
  abstract: Scalars['String']['input'];
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type VoteReportInput = {
  from?: InputMaybe<Scalars['Date']['input']>;
  to?: InputMaybe<Scalars['Date']['input']>;
};

export type WalletPayload = {
  signature: Scalars['String']['input'];
  token: Scalars['String']['input'];
  walletAddress: Scalars['String']['input'];
};

export type ConfirmEmailMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;

export type ConfirmEmailMutation = {
  __typename?: 'Mutation';
  confirmEmail: boolean;
};

export type SendResetPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type SendResetPasswordMutation = {
  __typename?: 'Mutation';
  sendResetPassword: boolean;
};

export type LoginMutationVariables = Exact<{
  input: LoginPayload;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login?: {
    __typename?: 'Account';
    id: number;
    email?: string | null;
    walletAddress?: string | null;
    username?: string | null;
    confirmed?: boolean | null;
    isOld?: boolean | null;
  } | null;
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = {
  __typename?: 'Mutation';
  logout?: {
    __typename?: 'Account';
    id: number;
    walletAddress?: string | null;
    username?: string | null;
    email?: string | null;
    isOld?: boolean | null;
  } | null;
};

export type PaymentIntentMutationVariables = Exact<{
  amount: Scalars['Int']['input'];
  currency: Scalars['String']['input'];
  paperId: Scalars['Int']['input'];
}>;

export type PaymentIntentMutation = {
  __typename?: 'Mutation';
  createPaymentIntent: {
    __typename?: 'PaymentIntentResponse';
    clientSecret: string;
  };
};

export type RegisterMutationVariables = Exact<{
  input: AccountPayload;
}>;

export type RegisterMutation = {
  __typename?: 'Mutation';
  registerAccount: {
    __typename?: 'Account';
    id: number;
    email?: string | null;
    walletAddress?: string | null;
    username?: string | null;
    confirmed?: boolean | null;
    isOld?: boolean | null;
  };
};

export type ResendConfirmationEmailMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ResendConfirmationEmailMutation = {
  __typename?: 'Mutation';
  resendConfirmEmail: boolean;
};

export type ResetPasswordMutationVariables = Exact<{
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword: boolean;
};

export type ReviewPaperMutationVariables = Exact<{
  input: CreatePaperReview;
}>;

export type ReviewPaperMutation = {
  __typename?: 'Mutation';
  reviewPaper?: { __typename?: 'Paper'; id: number } | null;
};

export type UpdateAccountMutationVariables = Exact<{
  input: AccountPayload;
}>;

export type UpdateAccountMutation = {
  __typename?: 'Mutation';
  updateAccount: {
    __typename?: 'Account';
    id: number;
    email?: string | null;
    walletAddress?: string | null;
    username?: string | null;
    confirmed?: boolean | null;
    isOld?: boolean | null;
  };
};

export type UploadPaperMutationVariables = Exact<{
  input: UploadPaperInput;
}>;

export type UploadPaperMutation = {
  __typename?: 'Mutation';
  uploadPaper: {
    __typename?: 'Paper';
    id: number;
    abstract: string;
    chainId?: number | null;
    url: string;
    paidUntil?: Date | null;
    sourcePlatform: string;
    title: string;
    uploadedOn: Date;
    uploadedBy?: { __typename?: 'Account'; id: number } | null;
  };
};

export type VotePaperMutationVariables = Exact<{
  paperId: Scalars['Int']['input'];
}>;

export type VotePaperMutation = {
  __typename?: 'Mutation';
  vote?: {
    __typename?: 'Paper';
    id: number;
    title: string;
    abstract: string;
    url: string;
    sourcePlatform: string;
    uploadedOn: Date;
    upvotedByAccount?: boolean | null;
    paidUntil?: Date | null;
    votes?: number | null;
  } | null;
};

export type VoteReviewMutationVariables = Exact<{
  reviewId: Scalars['Int']['input'];
}>;

export type VoteReviewMutation = {
  __typename?: 'Mutation';
  voteReview?: {
    __typename?: 'PaperReview';
    id: number;
    review: string;
    createdAt?: Date | null;
    paperId?: number | null;
    upvotedByAccount?: boolean | null;
    votes?: number | null;
    account?: {
      __typename?: 'Account';
      id: number;
      walletAddress?: string | null;
      username?: string | null;
      email?: string | null;
      isOld?: boolean | null;
    } | null;
  } | null;
};

export type WalletLoginMutationVariables = Exact<{
  input: WalletPayload;
}>;

export type WalletLoginMutation = {
  __typename?: 'Mutation';
  walletLogin?: {
    __typename?: 'Account';
    id: number;
    email?: string | null;
    walletAddress?: string | null;
    username?: string | null;
    confirmed?: boolean | null;
    isOld?: boolean | null;
  } | null;
};

export type AccountQueryVariables = Exact<{ [key: string]: never }>;

export type AccountQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    avatarUrl?: string | null;
    bio?: string | null;
    email?: string | null;
    id: number;
    firstName?: string | null;
    lastName?: string | null;
    isStaff: boolean;
    walletAddress?: string | null;
    username?: string | null;
    confirmed?: boolean | null;
    isOld?: boolean | null;
  };
};

export type AdminReportQueryVariables = Exact<{
  filter?: InputMaybe<VoteReportInput>;
}>;

export type AdminReportQuery = {
  __typename?: 'Query';
  report: Array<{
    __typename?: 'ReviewUpvoteReport';
    count: number;
    review: {
      __typename?: 'PaperReview';
      id: number;
      review: string;
      votes?: number | null;
      createdAt?: Date | null;
      account?: {
        __typename?: 'Account';
        id: number;
        email?: string | null;
        username?: string | null;
        walletAddress?: string | null;
      } | null;
      paper?: { __typename?: 'Paper'; id: number; title: string } | null;
    };
  } | null>;
};

export type MissingTxQueryVariables = Exact<{
  txHash: Scalars['String']['input'];
}>;

export type MissingTxQuery = {
  __typename?: 'Query';
  searchMissingPayment: boolean;
};

export type PaperQueryVariables = Exact<{
  paperId: Scalars['Int']['input'];
}>;

export type PaperQuery = {
  __typename?: 'Query';
  paper: {
    __typename?: 'Paper';
    id: number;
    abstract: string;
    chainId?: number | null;
    url: string;
    paidUntil?: Date | null;
    sourcePlatform: string;
    title: string;
    uploadedOn: Date;
    upvotedByAccount?: boolean | null;
    votes?: number | null;
    reviews?: Array<{
      __typename?: 'PaperReview';
      id: number;
      createdAt?: Date | null;
      review: string;
      upvotedByAccount?: boolean | null;
      votes?: number | null;
      account?: {
        __typename?: 'Account';
        id: number;
        email?: string | null;
        isAdmin: boolean;
        isStaff: boolean;
        walletAddress?: string | null;
        username?: string | null;
      } | null;
    }> | null;
  };
};

export type PapersQueryVariables = Exact<{
  filters?: InputMaybe<PaperFilters>;
}>;

export type PapersQuery = {
  __typename?: 'Query';
  papers: {
    __typename?: 'PapersResponse';
    count: number;
    papers: Array<{
      __typename?: 'Paper';
      id: number;
      abstract: string;
      chainId?: number | null;
      url: string;
      paidUntil?: Date | null;
      sourcePlatform: string;
      title: string;
      uploadedOn: Date;
      upvotedByAccount?: boolean | null;
      votes?: number | null;
    }>;
  };
};

export type PingQueryVariables = Exact<{ [key: string]: never }>;

export type PingQuery = {
  __typename?: 'Query';
  ping?: {
    __typename?: 'Account';
    id: number;
    walletAddress?: string | null;
    username?: string | null;
    email?: string | null;
    confirmed?: boolean | null;
    isOld?: boolean | null;
  } | null;
};

export type TokenQueryVariables = Exact<{ [key: string]: never }>;

export type TokenQuery = {
  __typename?: 'Query';
  signatureToken: { __typename?: 'Token'; token: string };
};

export type ValidateResetTokenQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;

export type ValidateResetTokenQuery = {
  __typename?: 'Query';
  validateResetPasswordToken: boolean;
};

export const ConfirmEmailDocument = gql`
  mutation ConfirmEmail($token: String!) {
    confirmEmail(token: $token)
  }
`;
export type ConfirmEmailMutationFn = Apollo.MutationFunction<
  ConfirmEmailMutation,
  ConfirmEmailMutationVariables
>;

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailMutation, { data, loading, error }] = useConfirmEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmEmailMutation,
    ConfirmEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmEmailMutation,
    ConfirmEmailMutationVariables
  >(ConfirmEmailDocument, options);
}
export type ConfirmEmailMutationHookResult = ReturnType<
  typeof useConfirmEmailMutation
>;
export type ConfirmEmailMutationResult =
  Apollo.MutationResult<ConfirmEmailMutation>;
export type ConfirmEmailMutationOptions = Apollo.BaseMutationOptions<
  ConfirmEmailMutation,
  ConfirmEmailMutationVariables
>;
export const SendResetPasswordDocument = gql`
  mutation SendResetPassword($email: String!) {
    sendResetPassword(email: $email)
  }
`;
export type SendResetPasswordMutationFn = Apollo.MutationFunction<
  SendResetPasswordMutation,
  SendResetPasswordMutationVariables
>;

/**
 * __useSendResetPasswordMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordMutation, { data, loading, error }] = useSendResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendResetPasswordMutation,
    SendResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendResetPasswordMutation,
    SendResetPasswordMutationVariables
  >(SendResetPasswordDocument, options);
}
export type SendResetPasswordMutationHookResult = ReturnType<
  typeof useSendResetPasswordMutation
>;
export type SendResetPasswordMutationResult =
  Apollo.MutationResult<SendResetPasswordMutation>;
export type SendResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  SendResetPasswordMutation,
  SendResetPasswordMutationVariables
>;
export const LoginDocument = gql`
  mutation Login($input: LoginPayload!) {
    login(input: $input) {
      id
      email
      walletAddress
      username
      confirmed
      isOld
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const LogoutDocument = gql`
  mutation Logout {
    logout {
      id
      walletAddress
      username
      email
      isOld
    }
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogoutMutation,
    LogoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    options,
  );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const PaymentIntentDocument = gql`
  mutation PaymentIntent($amount: Int!, $currency: String!, $paperId: Int!) {
    createPaymentIntent(
      amount: $amount
      currency: $currency
      paperId: $paperId
    ) {
      clientSecret
    }
  }
`;
export type PaymentIntentMutationFn = Apollo.MutationFunction<
  PaymentIntentMutation,
  PaymentIntentMutationVariables
>;

/**
 * __usePaymentIntentMutation__
 *
 * To run a mutation, you first call `usePaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentIntentMutation, { data, loading, error }] = usePaymentIntentMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      currency: // value for 'currency'
 *      paperId: // value for 'paperId'
 *   },
 * });
 */
export function usePaymentIntentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PaymentIntentMutation,
    PaymentIntentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PaymentIntentMutation,
    PaymentIntentMutationVariables
  >(PaymentIntentDocument, options);
}
export type PaymentIntentMutationHookResult = ReturnType<
  typeof usePaymentIntentMutation
>;
export type PaymentIntentMutationResult =
  Apollo.MutationResult<PaymentIntentMutation>;
export type PaymentIntentMutationOptions = Apollo.BaseMutationOptions<
  PaymentIntentMutation,
  PaymentIntentMutationVariables
>;
export const RegisterDocument = gql`
  mutation Register($input: AccountPayload!) {
    registerAccount(input: $input) {
      id
      email
      walletAddress
      username
      confirmed
      isOld
    }
  }
`;
export type RegisterMutationFn = Apollo.MutationFunction<
  RegisterMutation,
  RegisterMutationVariables
>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterMutation,
    RegisterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(
    RegisterDocument,
    options,
  );
}
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<
  RegisterMutation,
  RegisterMutationVariables
>;
export const ResendConfirmationEmailDocument = gql`
  mutation ResendConfirmationEmail {
    resendConfirmEmail
  }
`;
export type ResendConfirmationEmailMutationFn = Apollo.MutationFunction<
  ResendConfirmationEmailMutation,
  ResendConfirmationEmailMutationVariables
>;

/**
 * __useResendConfirmationEmailMutation__
 *
 * To run a mutation, you first call `useResendConfirmationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendConfirmationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendConfirmationEmailMutation, { data, loading, error }] = useResendConfirmationEmailMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendConfirmationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendConfirmationEmailMutation,
    ResendConfirmationEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendConfirmationEmailMutation,
    ResendConfirmationEmailMutationVariables
  >(ResendConfirmationEmailDocument, options);
}
export type ResendConfirmationEmailMutationHookResult = ReturnType<
  typeof useResendConfirmationEmailMutation
>;
export type ResendConfirmationEmailMutationResult =
  Apollo.MutationResult<ResendConfirmationEmailMutation>;
export type ResendConfirmationEmailMutationOptions = Apollo.BaseMutationOptions<
  ResendConfirmationEmailMutation,
  ResendConfirmationEmailMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($newPassword: String!, $token: String!) {
    resetPassword(newPassword: $newPassword, token: $token)
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const ReviewPaperDocument = gql`
  mutation ReviewPaper($input: CreatePaperReview!) {
    reviewPaper(input: $input) {
      id
    }
  }
`;
export type ReviewPaperMutationFn = Apollo.MutationFunction<
  ReviewPaperMutation,
  ReviewPaperMutationVariables
>;

/**
 * __useReviewPaperMutation__
 *
 * To run a mutation, you first call `useReviewPaperMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewPaperMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewPaperMutation, { data, loading, error }] = useReviewPaperMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewPaperMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReviewPaperMutation,
    ReviewPaperMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReviewPaperMutation, ReviewPaperMutationVariables>(
    ReviewPaperDocument,
    options,
  );
}
export type ReviewPaperMutationHookResult = ReturnType<
  typeof useReviewPaperMutation
>;
export type ReviewPaperMutationResult =
  Apollo.MutationResult<ReviewPaperMutation>;
export type ReviewPaperMutationOptions = Apollo.BaseMutationOptions<
  ReviewPaperMutation,
  ReviewPaperMutationVariables
>;
export const UpdateAccountDocument = gql`
  mutation UpdateAccount($input: AccountPayload!) {
    updateAccount(input: $input) {
      id
      email
      walletAddress
      username
      confirmed
      isOld
    }
  }
`;
export type UpdateAccountMutationFn = Apollo.MutationFunction<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountMutation,
    UpdateAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAccountMutation,
    UpdateAccountMutationVariables
  >(UpdateAccountDocument, options);
}
export type UpdateAccountMutationHookResult = ReturnType<
  typeof useUpdateAccountMutation
>;
export type UpdateAccountMutationResult =
  Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;
export const UploadPaperDocument = gql`
  mutation UploadPaper($input: UploadPaperInput!) {
    uploadPaper(input: $input) {
      id
      abstract
      chainId
      url
      paidUntil
      sourcePlatform
      title
      uploadedOn
      uploadedBy {
        id
      }
    }
  }
`;
export type UploadPaperMutationFn = Apollo.MutationFunction<
  UploadPaperMutation,
  UploadPaperMutationVariables
>;

/**
 * __useUploadPaperMutation__
 *
 * To run a mutation, you first call `useUploadPaperMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPaperMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPaperMutation, { data, loading, error }] = useUploadPaperMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadPaperMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadPaperMutation,
    UploadPaperMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadPaperMutation, UploadPaperMutationVariables>(
    UploadPaperDocument,
    options,
  );
}
export type UploadPaperMutationHookResult = ReturnType<
  typeof useUploadPaperMutation
>;
export type UploadPaperMutationResult =
  Apollo.MutationResult<UploadPaperMutation>;
export type UploadPaperMutationOptions = Apollo.BaseMutationOptions<
  UploadPaperMutation,
  UploadPaperMutationVariables
>;
export const VotePaperDocument = gql`
  mutation VotePaper($paperId: Int!) {
    vote(paperId: $paperId) {
      id
      title
      abstract
      url
      sourcePlatform
      uploadedOn
      upvotedByAccount
      paidUntil
      votes
    }
  }
`;
export type VotePaperMutationFn = Apollo.MutationFunction<
  VotePaperMutation,
  VotePaperMutationVariables
>;

/**
 * __useVotePaperMutation__
 *
 * To run a mutation, you first call `useVotePaperMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVotePaperMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [votePaperMutation, { data, loading, error }] = useVotePaperMutation({
 *   variables: {
 *      paperId: // value for 'paperId'
 *   },
 * });
 */
export function useVotePaperMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VotePaperMutation,
    VotePaperMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VotePaperMutation, VotePaperMutationVariables>(
    VotePaperDocument,
    options,
  );
}
export type VotePaperMutationHookResult = ReturnType<
  typeof useVotePaperMutation
>;
export type VotePaperMutationResult = Apollo.MutationResult<VotePaperMutation>;
export type VotePaperMutationOptions = Apollo.BaseMutationOptions<
  VotePaperMutation,
  VotePaperMutationVariables
>;
export const VoteReviewDocument = gql`
  mutation VoteReview($reviewId: Int!) {
    voteReview(reviewId: $reviewId) {
      id
      review
      createdAt
      paperId
      account {
        id
        walletAddress
        username
        email
        isOld
      }
      upvotedByAccount
      votes
    }
  }
`;
export type VoteReviewMutationFn = Apollo.MutationFunction<
  VoteReviewMutation,
  VoteReviewMutationVariables
>;

/**
 * __useVoteReviewMutation__
 *
 * To run a mutation, you first call `useVoteReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoteReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voteReviewMutation, { data, loading, error }] = useVoteReviewMutation({
 *   variables: {
 *      reviewId: // value for 'reviewId'
 *   },
 * });
 */
export function useVoteReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VoteReviewMutation,
    VoteReviewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VoteReviewMutation, VoteReviewMutationVariables>(
    VoteReviewDocument,
    options,
  );
}
export type VoteReviewMutationHookResult = ReturnType<
  typeof useVoteReviewMutation
>;
export type VoteReviewMutationResult =
  Apollo.MutationResult<VoteReviewMutation>;
export type VoteReviewMutationOptions = Apollo.BaseMutationOptions<
  VoteReviewMutation,
  VoteReviewMutationVariables
>;
export const WalletLoginDocument = gql`
  mutation WalletLogin($input: WalletPayload!) {
    walletLogin(input: $input) {
      id
      email
      walletAddress
      username
      confirmed
      isOld
    }
  }
`;
export type WalletLoginMutationFn = Apollo.MutationFunction<
  WalletLoginMutation,
  WalletLoginMutationVariables
>;

/**
 * __useWalletLoginMutation__
 *
 * To run a mutation, you first call `useWalletLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWalletLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [walletLoginMutation, { data, loading, error }] = useWalletLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWalletLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WalletLoginMutation,
    WalletLoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<WalletLoginMutation, WalletLoginMutationVariables>(
    WalletLoginDocument,
    options,
  );
}
export type WalletLoginMutationHookResult = ReturnType<
  typeof useWalletLoginMutation
>;
export type WalletLoginMutationResult =
  Apollo.MutationResult<WalletLoginMutation>;
export type WalletLoginMutationOptions = Apollo.BaseMutationOptions<
  WalletLoginMutation,
  WalletLoginMutationVariables
>;
export const AccountDocument = gql`
  query Account {
    account {
      avatarUrl
      bio
      email
      id
      firstName
      lastName
      isStaff
      walletAddress
      username
      confirmed
      isOld
    }
  }
`;

/**
 * __useAccountQuery__
 *
 * To run a query within a React component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<AccountQuery, AccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountQuery, AccountQueryVariables>(
    AccountDocument,
    options,
  );
}
export function useAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AccountQuery,
    AccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountQuery, AccountQueryVariables>(
    AccountDocument,
    options,
  );
}
export function useAccountSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AccountQuery,
    AccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AccountQuery, AccountQueryVariables>(
    AccountDocument,
    options,
  );
}
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export type AccountSuspenseQueryHookResult = ReturnType<
  typeof useAccountSuspenseQuery
>;
export type AccountQueryResult = Apollo.QueryResult<
  AccountQuery,
  AccountQueryVariables
>;
export const AdminReportDocument = gql`
  query AdminReport($filter: VoteReportInput) {
    report(filter: $filter) {
      count
      review {
        account {
          id
          email
          username
          walletAddress
        }
        paper {
          id
          title
        }
        id
        review
        votes
        createdAt
      }
    }
  }
`;

/**
 * __useAdminReportQuery__
 *
 * To run a query within a React component, call `useAdminReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminReportQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminReportQuery,
    AdminReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminReportQuery, AdminReportQueryVariables>(
    AdminReportDocument,
    options,
  );
}
export function useAdminReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminReportQuery,
    AdminReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminReportQuery, AdminReportQueryVariables>(
    AdminReportDocument,
    options,
  );
}
export function useAdminReportSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AdminReportQuery,
    AdminReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminReportQuery, AdminReportQueryVariables>(
    AdminReportDocument,
    options,
  );
}
export type AdminReportQueryHookResult = ReturnType<typeof useAdminReportQuery>;
export type AdminReportLazyQueryHookResult = ReturnType<
  typeof useAdminReportLazyQuery
>;
export type AdminReportSuspenseQueryHookResult = ReturnType<
  typeof useAdminReportSuspenseQuery
>;
export type AdminReportQueryResult = Apollo.QueryResult<
  AdminReportQuery,
  AdminReportQueryVariables
>;
export const MissingTxDocument = gql`
  query MissingTx($txHash: String!) {
    searchMissingPayment(tx: $txHash)
  }
`;

/**
 * __useMissingTxQuery__
 *
 * To run a query within a React component, call `useMissingTxQuery` and pass it any options that fit your needs.
 * When your component renders, `useMissingTxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMissingTxQuery({
 *   variables: {
 *      txHash: // value for 'txHash'
 *   },
 * });
 */
export function useMissingTxQuery(
  baseOptions: Apollo.QueryHookOptions<MissingTxQuery, MissingTxQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MissingTxQuery, MissingTxQueryVariables>(
    MissingTxDocument,
    options,
  );
}
export function useMissingTxLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MissingTxQuery,
    MissingTxQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MissingTxQuery, MissingTxQueryVariables>(
    MissingTxDocument,
    options,
  );
}
export function useMissingTxSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    MissingTxQuery,
    MissingTxQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MissingTxQuery, MissingTxQueryVariables>(
    MissingTxDocument,
    options,
  );
}
export type MissingTxQueryHookResult = ReturnType<typeof useMissingTxQuery>;
export type MissingTxLazyQueryHookResult = ReturnType<
  typeof useMissingTxLazyQuery
>;
export type MissingTxSuspenseQueryHookResult = ReturnType<
  typeof useMissingTxSuspenseQuery
>;
export type MissingTxQueryResult = Apollo.QueryResult<
  MissingTxQuery,
  MissingTxQueryVariables
>;
export const PaperDocument = gql`
  query Paper($paperId: Int!) {
    paper(paperId: $paperId) {
      id
      abstract
      chainId
      url
      paidUntil
      sourcePlatform
      title
      uploadedOn
      upvotedByAccount
      votes
      reviews {
        id
        createdAt
        review
        account {
          id
          email
          isAdmin
          isStaff
          walletAddress
          username
        }
        upvotedByAccount
        votes
      }
    }
  }
`;

/**
 * __usePaperQuery__
 *
 * To run a query within a React component, call `usePaperQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaperQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaperQuery({
 *   variables: {
 *      paperId: // value for 'paperId'
 *   },
 * });
 */
export function usePaperQuery(
  baseOptions: Apollo.QueryHookOptions<PaperQuery, PaperQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaperQuery, PaperQueryVariables>(
    PaperDocument,
    options,
  );
}
export function usePaperLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaperQuery, PaperQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaperQuery, PaperQueryVariables>(
    PaperDocument,
    options,
  );
}
export function usePaperSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PaperQuery,
    PaperQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PaperQuery, PaperQueryVariables>(
    PaperDocument,
    options,
  );
}
export type PaperQueryHookResult = ReturnType<typeof usePaperQuery>;
export type PaperLazyQueryHookResult = ReturnType<typeof usePaperLazyQuery>;
export type PaperSuspenseQueryHookResult = ReturnType<
  typeof usePaperSuspenseQuery
>;
export type PaperQueryResult = Apollo.QueryResult<
  PaperQuery,
  PaperQueryVariables
>;
export const PapersDocument = gql`
  query Papers($filters: PaperFilters) {
    papers(filters: $filters) {
      papers {
        id
        abstract
        chainId
        url
        paidUntil
        sourcePlatform
        title
        uploadedOn
        upvotedByAccount
        votes
      }
      count
    }
  }
`;

/**
 * __usePapersQuery__
 *
 * To run a query within a React component, call `usePapersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePapersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePapersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePapersQuery(
  baseOptions?: Apollo.QueryHookOptions<PapersQuery, PapersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PapersQuery, PapersQueryVariables>(
    PapersDocument,
    options,
  );
}
export function usePapersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PapersQuery, PapersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PapersQuery, PapersQueryVariables>(
    PapersDocument,
    options,
  );
}
export function usePapersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PapersQuery,
    PapersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PapersQuery, PapersQueryVariables>(
    PapersDocument,
    options,
  );
}
export type PapersQueryHookResult = ReturnType<typeof usePapersQuery>;
export type PapersLazyQueryHookResult = ReturnType<typeof usePapersLazyQuery>;
export type PapersSuspenseQueryHookResult = ReturnType<
  typeof usePapersSuspenseQuery
>;
export type PapersQueryResult = Apollo.QueryResult<
  PapersQuery,
  PapersQueryVariables
>;
export const PingDocument = gql`
  query Ping {
    ping {
      id
      walletAddress
      username
      email
      confirmed
      isOld
    }
  }
`;

/**
 * __usePingQuery__
 *
 * To run a query within a React component, call `usePingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePingQuery({
 *   variables: {
 *   },
 * });
 */
export function usePingQuery(
  baseOptions?: Apollo.QueryHookOptions<PingQuery, PingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PingQuery, PingQueryVariables>(PingDocument, options);
}
export function usePingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PingQuery, PingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PingQuery, PingQueryVariables>(
    PingDocument,
    options,
  );
}
export function usePingSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PingQuery, PingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<PingQuery, PingQueryVariables>(
    PingDocument,
    options,
  );
}
export type PingQueryHookResult = ReturnType<typeof usePingQuery>;
export type PingLazyQueryHookResult = ReturnType<typeof usePingLazyQuery>;
export type PingSuspenseQueryHookResult = ReturnType<
  typeof usePingSuspenseQuery
>;
export type PingQueryResult = Apollo.QueryResult<PingQuery, PingQueryVariables>;
export const TokenDocument = gql`
  query Token {
    signatureToken {
      token
    }
  }
`;

/**
 * __useTokenQuery__
 *
 * To run a query within a React component, call `useTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<TokenQuery, TokenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TokenQuery, TokenQueryVariables>(
    TokenDocument,
    options,
  );
}
export function useTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TokenQuery, TokenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TokenQuery, TokenQueryVariables>(
    TokenDocument,
    options,
  );
}
export function useTokenSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    TokenQuery,
    TokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TokenQuery, TokenQueryVariables>(
    TokenDocument,
    options,
  );
}
export type TokenQueryHookResult = ReturnType<typeof useTokenQuery>;
export type TokenLazyQueryHookResult = ReturnType<typeof useTokenLazyQuery>;
export type TokenSuspenseQueryHookResult = ReturnType<
  typeof useTokenSuspenseQuery
>;
export type TokenQueryResult = Apollo.QueryResult<
  TokenQuery,
  TokenQueryVariables
>;
export const ValidateResetTokenDocument = gql`
  query ValidateResetToken($token: String!) {
    validateResetPasswordToken(token: $token)
  }
`;

/**
 * __useValidateResetTokenQuery__
 *
 * To run a query within a React component, call `useValidateResetTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateResetTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateResetTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useValidateResetTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidateResetTokenQuery,
    ValidateResetTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ValidateResetTokenQuery,
    ValidateResetTokenQueryVariables
  >(ValidateResetTokenDocument, options);
}
export function useValidateResetTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidateResetTokenQuery,
    ValidateResetTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ValidateResetTokenQuery,
    ValidateResetTokenQueryVariables
  >(ValidateResetTokenDocument, options);
}
export function useValidateResetTokenSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ValidateResetTokenQuery,
    ValidateResetTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ValidateResetTokenQuery,
    ValidateResetTokenQueryVariables
  >(ValidateResetTokenDocument, options);
}
export type ValidateResetTokenQueryHookResult = ReturnType<
  typeof useValidateResetTokenQuery
>;
export type ValidateResetTokenLazyQueryHookResult = ReturnType<
  typeof useValidateResetTokenLazyQuery
>;
export type ValidateResetTokenSuspenseQueryHookResult = ReturnType<
  typeof useValidateResetTokenSuspenseQuery
>;
export type ValidateResetTokenQueryResult = Apollo.QueryResult<
  ValidateResetTokenQuery,
  ValidateResetTokenQueryVariables
>;

type Properties<T> = Required<{
  [K in keyof T]: z.ZodType<T[K], any, T[K]>;
}>;

type definedNonNullAny = {};

export const isDefinedNonNullAny = (v: any): v is definedNonNullAny =>
  v !== undefined && v !== null;

export const definedNonNullAnySchema = z
  .any()
  .refine((v) => isDefinedNonNullAny(v));

export function AccountPayloadSchema(): z.ZodObject<
  Properties<AccountPayload>
> {
  return z.object({
    avatarUrl: z.string().nullish(),
    bio: z.string().nullish(),
    email: z.string().nullish(),
    firstName: z.string().nullish(),
    lastName: z.string().nullish(),
    password: z.string().nullish(),
    phoneNumber: z.string().nullish(),
    username: z.string().nullish(),
    walletAddress: z.string().nullish(),
  });
}

export function CreatePaperReviewSchema(): z.ZodObject<
  Properties<CreatePaperReview>
> {
  return z.object({
    paperId: z.number(),
    review: z.string(),
  });
}

export function LoginPayloadSchema(): z.ZodObject<Properties<LoginPayload>> {
  return z.object({
    email: z.string().nullish(),
    password: z.string(),
    username: z.string().nullish(),
  });
}

export function PaperFiltersSchema(): z.ZodObject<Properties<PaperFilters>> {
  return z.object({
    onlyPaid: z.boolean().nullish(),
    onlyUser: z.boolean().nullish(),
    page: z.number().nullish(),
    searchTerm: z.string().nullish(),
  });
}

export function UploadPaperInputSchema(): z.ZodObject<
  Properties<UploadPaperInput>
> {
  return z.object({
    abstract: z.string(),
    title: z.string(),
    url: z.string(),
  });
}

export function VoteReportInputSchema(): z.ZodObject<
  Properties<VoteReportInput>
> {
  return z.object({
    from: z.date().nullish(),
    to: z.date().nullish(),
  });
}

export function WalletPayloadSchema(): z.ZodObject<Properties<WalletPayload>> {
  return z.object({
    signature: z.string(),
    token: z.string(),
    walletAddress: z.string(),
  });
}
