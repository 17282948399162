import ViewContainer from '../components/View/ViewContainer';
import { Center, Heading, Link, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { useAdminReportQuery } from '../graphql/generated';
import Loader from '../components/Loader';
import React from 'react';
import fmtUser from '../utils/fmtUser';
import moment from 'moment';

const Admin = () => {
    const { data, loading, error } = useAdminReportQuery();
    return (
        <ViewContainer>
            <Heading w='100%' textAlign='center' size='sm' my={5}>
                MTD Review upvotes
            </Heading>
            <Center>
                { loading && <Loader /> }
                { error && <Text color='red'>Something went wrong. Contact support</Text> }
                { data &&
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Account</Th>
                                <Th>Paper</Th>
                                <Th>Votes (total)</Th>
                                <Th>Review Text</Th>
                                <Th>Created At</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data.report.map(({ review, count }: any) => (
                                <Tr key={review.id}>
                                    <Td>{fmtUser(review.account)}</Td>
                                    <Td>
                                        <Link isExternal href={`/preprint/${review.paper.id}`}>
                                            {review.paper.title.slice(0, 50)}
                                        </Link>
                                    </Td>
                                    <Td textAlign='center'>
                                        <Text fontWeight='bold'>{count}</Text>
                                        <Text size='sm' color='gray.400'>({review.votes})</Text>
                                    </Td>
                                    <Td>
                                        {review.review.slice(0, 100)}
                                    </Td>
                                    <Td>{moment(review.createdAt).fromNow()}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                }
            </Center>
        </ViewContainer>
    )
}

export default Admin;
