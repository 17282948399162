import ViewContainer from '../components/View/ViewContainer';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import React, { useContext } from 'react';
import * as z from 'zod';
import { AuthContext } from '../components/Providers/AuthProvider';
import isValidUrl from '../utils/isValidUrl';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, FormControl, FormErrorMessage, FormLabel, Input, Stack, Textarea, useToast } from '@chakra-ui/react';
import { useUploadPaperMutation } from '../graphql/generated';
import { useNavigate } from 'react-router-dom';

const UploadSchema = z.object({
    title: z.string().min(10),
    abstract: z.string().min(50),
    url: z.string().refine(url => {
        return url.includes('medrxiv.org')
            || url.includes('biorxiv.org')
            || url.includes('arxiv.org')
            || isValidUrl(url);
    }, {
        message: 'URL must be valid and from "medrxiv", "bioRxiv", or "arXiv"',
    }),
});

type Schema = z.infer<typeof UploadSchema>;

const UploadPaper = () => {
    const { isLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();
    const toast = useToast();
    const [uploadPaper, { loading }] = useUploadPaperMutation({
        onError: (error) => {
            toast({
                title: 'Upload failed',
                description: error.message,
                status: 'error',
                position: 'top',
                duration: 5000,
                isClosable: true,
            });
        }
    });

    const uploadForm = useForm<Schema>({
        mode: 'onChange',
        resolver: zodResolver(UploadSchema),
    });
    const { control, formState: { errors } } = uploadForm;

    const onSubmit = async () => {
        if (!isLoggedIn) {
            return;
        }

        const { title, abstract, url } = uploadForm.getValues();
        const { data, errors } = await uploadPaper({
            variables: {
                input: {
                    title,
                    abstract,
                    url,
                }
            }
        });
        if (errors) {
            return;
        }
        if (data?.uploadPaper) {
            await navigate(`/preprint/${data.uploadPaper.id}`)
        }
    }
    return (
        <ViewContainer>
            <FormProvider {...uploadForm}>
                <Stack gap={5} maxW='1020px'>
                    <FormControl isRequired isInvalid={!!errors?.url}>
                        <FormLabel>Link</FormLabel>
                        <Controller
                            control={control}
                            render={({ field: { onChange, value }}: any) => (
                                <>
                                    <Input
                                        autoComplete='off'
                                        h={10}
                                        bg='gray.50'
                                        onChange={onChange}
                                        placeholder='URL of your paper (only from medrxiv, bioRxiv, arXiv)'
                                        size='sm'
                                        type='text'
                                        value={value} />
                                    <FormErrorMessage>
                                        { errors?.url?.message }
                                    </FormErrorMessage>
                                </>
                            )}
                            name='url'
                            defaultValue='' />
                    </FormControl>
                    <FormControl isRequired isInvalid={!!errors?.title}>
                        <FormLabel>Title</FormLabel>
                        <Controller
                            control={control}
                            render={({ field: { onChange, value }}: any) => (
                                <>
                                    <Input
                                        autoComplete='off'
                                        h={10}
                                        bg='gray.50'
                                        onChange={onChange}
                                        placeholder='Title of your Preprint'
                                        size='sm'
                                        type='text'
                                        value={value} />
                                    <FormErrorMessage>
                                        { errors?.title?.message }
                                    </FormErrorMessage>
                                </>
                            )}
                            name='title'
                            defaultValue='' />
                    </FormControl>
                    <FormControl isRequired isInvalid={!!errors?.abstract}>
                        <FormLabel>Abstract</FormLabel>
                        <Controller
                            control={control}
                            render={({ field: { onChange, value }}: any) => (
                                <>
                                    <Textarea
                                        autoComplete='off'
                                        h={10}
                                        bg='gray.50'
                                        onChange={onChange}
                                        placeholder='Abstract of your preprint'
                                        size='sm'
                                        value={value} />
                                    <FormErrorMessage>
                                        { errors?.abstract?.message }
                                    </FormErrorMessage>
                                </>
                            )}
                            name='abstract'
                            defaultValue='' />
                    </FormControl>
                    <Button variant='black' isDisabled={!isLoggedIn} isLoading={loading} onClick={onSubmit}>
                        Submit
                    </Button>
                </Stack>
            </FormProvider>
        </ViewContainer>
    );
}

export default UploadPaper;
