const Input = {
    baseStyle: {
        height: '10rem',
        background: 'gray.50'
    },
    _hover: {
        fontWeight: 'bold',
    },
};

export default Input;