import {
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Heading,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay, Spacer,
    useToast
} from '@chakra-ui/react';
import React, { useCallback, useContext, useState } from 'react';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import ConnectWallet from '../../Buttons/ConnectWallet';
import { useLoginMutation, useRegisterMutation } from '../../../graphql/generated';
import { AuthContext } from '../../Providers/AuthProvider';
import RegisterForm from './RegisterForm';
import ForgotForm from './ForgotForm';

interface RegisterModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const RegisterSchema = z.object({
    email: z.string().email(),
    password: z.string().min(5),
    firstName: z.string().nullable(),
    lastName: z.string().nullable(),
    username: z.string().nullable(),
    bio: z.string().nullable()
})

export type RegSchema = z.infer<typeof RegisterSchema>;

const RegisterModal: React.FC<RegisterModalProps> = ({ isOpen, onClose }) => {
    const toast = useToast();
    const { setAuthValues } = useContext(AuthContext);

    const [isRegister, setIsRegister] = useState<boolean>(false);
    const [isReset, setIsReset] = useState<boolean>(false);
    const registerForm = useForm<RegSchema>({
        mode: 'onChange',
        resolver: zodResolver(RegisterSchema),
    });

    const { watch } = registerForm;

    const [login, { loading, error: loginError }] = useLoginMutation({
        onError(err) {
            if (err.message.includes('Please reset your password')) {
                toast({
                    title: 'Old account detected!',
                    description: err.message,
                    position: 'top',
                    status: 'warning',
                    duration: 5000
                });
            } else {
                toast({
                    title: err.message,
                    position: 'top',
                    status: 'error',
                    duration: 5000
                });
            }
        }
    });

    const [register, { loading: registerLoading, error: registerError }] = useRegisterMutation({
        onError(err) {
            toast({
                title: err.message,
                position: 'top',
                status: 'error',
                duration: 3000
            });
        }
    });

    const password = watch('password');
    const email = watch('email');

    const closeModal = () => {
        onClose();
        setIsRegister(false);
        setIsReset(false);
    }

    const onLogin = useCallback( async () => {
        try {
            let result;
            if (!isRegister) {
                result = await login({
                    variables: {
                        input: { email: email, password: password }
                    }
                });
                if (result.data) {
                    setAuthValues(result.data.login, true);
                }
            } else {
                result = await register({
                    variables: {
                        input: { ...registerForm.getValues() }
                    }
                });
                if (result.data) {
                    setAuthValues(result.data.registerAccount, true);
                }
            }

            if (result.data) {
                closeModal();
                toast({
                    description: `Successfully ${isRegister ? 'registered' : 'logged in'}`,
                    position: 'top',
                    duration: 3000,
                    isClosable: true,
                });
            }
        } catch (err) {
            console.info(err);
        }
    }, [login, email, password, isRegister, loginError, registerError]);

    return (
        <Modal isOpen={isOpen} onClose={closeModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader textAlign='center' fontWeight='bold'>
                    { !isReset && isRegister && 'Register' }
                    { !isReset && !isRegister && 'Log in' }
                    { isReset && 'Reset Password' }
                    <ModalCloseButton />
                </ModalHeader>
                <ModalBody>
                    {!isReset &&
                        <>
                            <Box mb={3} p={2}>
                                <RegisterForm registerForm={registerForm} isRegister={isRegister} />
                                <Flex>
                                    <Link mt={1} onClick={() => setIsRegister(!isRegister)}>
                                        { !isRegister && 'Register' }
                                        { isRegister && 'Log In' }
                                    </Link>
                                    <Spacer />
                                    <Link mt={1} onClick={() => setIsReset(true)}>
                                        reset password
                                    </Link>
                                </Flex>
                                <Button
                                    w='100%'
                                    mt={2}
                                    variant='black'
                                    onClick={onLogin}
                                    isDisabled={!email || !password}
                                    isLoading={loading || registerLoading}>
                                    { isRegister && 'Register' }
                                    { !isRegister && 'Log in' }
                                </Button>
                            </Box>
                            <Divider />
                            <Box mt={3} p={2} textAlign='center'>
                                <Heading size='xs' color='gray.800' mb={2}>
                                    { isRegister && 'Register ' }
                                    { !isRegister && 'Log in ' }
                                     with wallet
                                </Heading>
                                <Center w='100%'  alignItems='center' alignContent='center'>
                                    <ConnectWallet onClose={closeModal} />
                                </Center>
                            </Box>
                        </>
                    }
                    {isReset &&
                        <Box mb={3} p={2}>
                            <ForgotForm onClose={closeModal} />
                        </Box>
                    }
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default RegisterModal;
