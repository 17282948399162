import { Box, Image, keyframes, usePrefersReducedMotion } from '@chakra-ui/react';
import React from 'react';
import MiniLogo from '../assets/logos/tldr-blocks.png';

const fadeInOut = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;


interface LoaderProps {
    h?: number;
}

const Loader: React.FC<LoaderProps> = ({ h = 120 }) => {
    const prefersReducedMotion = usePrefersReducedMotion();
    const animation = prefersReducedMotion
        ? undefined
        : `${fadeInOut} infinite 2s ease-in-out`;

    return (
        <Box
            my={10}
            display='flex'
            justifyContent='center'
            alignItems='center'
            animation={animation}>
            <Image src={MiniLogo} h={h} />
        </Box>
    );
}

export default Loader;