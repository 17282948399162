import React from 'react';
import { base, baseGoerli } from 'viem/chains';
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected';
import { publicProvider } from 'wagmi/providers/public'

const isProd = process.env.NODE_ENV !== 'development';
const chains = isProd ? [base] : [baseGoerli];
const { publicClient, webSocketPublicClient } = configureChains(
    [...chains],
    [publicProvider()],
);


export const connector = new InjectedConnector({
    chains,
})

const config = createConfig({
    autoConnect: true,
    publicClient,
    webSocketPublicClient,
    connectors: [connector]
})

export const Web3Provider: React.FC<{ children: any }> = ({ children }) => {
    return (
        <WagmiConfig config={config}>
            {children}
        </WagmiConfig>
    );
}