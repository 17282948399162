import React, { createContext, useState } from 'react';
import { Account, PingQuery, usePingQuery, WalletLoginMutation } from '../../graphql/generated';

type IAccount = PingQuery['ping'];
type IAccount2 = WalletLoginMutation['walletLogin'];

interface IAuthContext {
    isLoggedIn: boolean;
    account: IAccount | null;
    setAuthValues: (account: IAccount | IAccount2 | Account, isLoggedIn: boolean) => void;
}

export const AuthContext = createContext<IAuthContext>({
    isLoggedIn: false,
    account: null,
    setAuthValues: () => undefined,
});

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [account, setAccount] = useState<IAccount | null>(null);

    usePingQuery({
        onCompleted(data) {
            if (data && data.ping) {
                setIsLoggedIn(true);
                setAccount(data.ping)
            }
        }
    });

    const setAuthValues = (account: IAccount | IAccount2 | Account, isLoggedIn: boolean) => {
        setAccount(account);
        setIsLoggedIn(isLoggedIn);
    }

    return (
        <AuthContext.Provider value={{
            isLoggedIn,
            account,
            setAuthValues,
        }}>
            { children }
        </AuthContext.Provider>
    )
}

export default AuthProvider;
